import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { BrowserRouter, Route, Switch, Link, Redirect } from "react-router-dom";
import ReactCodeInput from 'react-verification-code-input';
//https://snack.expo.io/@retyui/demo-for-react-native-confirmation-code-field
import { useCookies } from 'react-cookie';
import Loading from '../_components/Loading';




const Login = () => {

    const [loading, setloading] = useState(false);
    const [redirect, setredirect] = useState(false);
    const [cookies, setCookie] = useCookies(['has_started']);

    const onFinishCheckingCode = code => {
      setloading(true);
      setTimeout(function(){

        if (code !== '1234') {
          alert("Pin errado! Tenta novamente.");
          setloading(false);
          return false;
        }

        setCookie('has_started', "1332323", { path: '/' });

        setredirect(true);

       }, 3000);
    };

    return(

      <div className="page login-page">
        <div className="center">
        {!cookies.in_fullscreen ? <Redirect to='/' /> : ""}
        {redirect ? <Redirect to='/play' /> : ""}
        {loading ? <Loading /> :
          <>
          <div className="title">
            <div className="icon-wrapper">
              <i className="fa fa-lock" aria-hidden="true"></i>
            </div>
            <h2>PIN de Verificação</h2>
            <p>Por favor insira o PIN de verificação.</p>
          </div>
          <ReactCodeInput className="pin-wrapper" onComplete={onFinishCheckingCode} fields={4} type="number"/>
          </>
        }
        </div>
      </div>


    );

}

export default Login;