import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Modal, Button } from 'react-bootstrap';
import { BrowserRouter, Route, Switch, Link, Redirect } from "react-router-dom";


import Game from '../_components/Game';
import Header from '../_components/Header';
import Loading from '../_components/Loading';
import Sketch from "react-p5";
import { useCookies } from 'react-cookie';
import P5Wrapper from 'react-p5-wrapper';



const Play = () => {

  const [cookies, setCookie, removeCookie] = useCookies(['has_started']);
  const [redirect, setredirect] = useState(false);
  let [points, setPoints] = useState(0);
  let [lifes, setLifes] = useState(3);
  let [dummy, setDummy] = useState(1);


  useEffect(() => {
    setTimeout(function(){
      console.log("COMPOENT LOADED");
      setDummy(3);
      removeCookie("in_fullscreen");
    },500);
  }, []);



  const test = () => {
    console.log("touched ----------");
  }

  const checkLifes = () => {
    setLifes(lifes - 1);
    console.log("LIFES: "+lifes);
    if(lifes == 1){
      resetGame();
    }
  }

  const resetGame = () => {
    setPoints(0);
    setLifes(3);
    setredirect(true);
  }

  return(

    <div className="page play-page" id="lovePage">
    {!cookies.has_started ? <Redirect to='/gameover' /> : ""}
    {redirect ? <Redirect to='/gameover' /> : ""}
    <div id="p5_loading" class="center">
    <Loading />
    </div>
    <Header points={points} lifes={lifes} />
    <P5Wrapper sketch={Game} currentPoints={points} dummy={dummy} setPoints={() => setPoints(points + 1)} setLifes={() => checkLifes()} setLifesalt={() => test()} />
    </div>



  );

}

export default Play;