import React, { useState, useEffect } from 'react';
import './App.css';
import {BrowserRouter, withRouter, Switch, Route, Link, Redirect, useLocation} from 'react-router-dom';
import { useCookies } from 'react-cookie';
import classNames from "classnames";
import Fullscreen from "react-full-screen";

import Login from './_containers/Login';
import Play from './_containers/Play';
import Start from './_containers/Start';
import Gameover from './_containers/Gameover';
import BackgroundEffect from './_components/BackgroundEffect';

const PrivateRoute = ({ component: Comp, path, ...rest }) => {

  const [cookies, setCookie] = useCookies(['has_started']);

  return (
    <Route
    path={path}
    {...rest}
    render={props => {
      return cookies.has_started ? <Comp {...props} /> : <Redirect to="/login" />;
    }}
    />
  );
};

function App() {

  const [cookies, setCookie] = useCookies(['has_started']);
  const [redirect, setredirect] = useState(false);
  let [full, setFull] = useState(false);
  let location = useLocation();
  let path = location.pathname;
  let inputElement;

  const goFull = () => {
    setFull(true);
  }

  useEffect(() => {
    setTimeout(function(){
      if( cookies.in_fullscreen ) {
        setredirect(true);
      }
    },1000);

  }, []);

  return (
    <Fullscreen enabled={full} onChange={full => setFull(true)} >
    {redirect ? <Redirect to='/login' /> : ""}
    <div className="frame">
    <div className="inside-frame">
    <Switch>

    <PrivateRoute exact path="/play" component={Play} />
    <PrivateRoute exact path="/gameover" component={Gameover} />
    <Route exact path="/login" component={Login} />
    <Route exact path="/" component={() => <Start goFull={() => goFull()} />} />

    </Switch>
    <BackgroundEffect type="love"/>
    </div>
    </div>
    </Fullscreen>

  );
}

export default App;