import React, { useState, useEffect } from 'react';
import P5Wrapper from 'react-p5-wrapper';

const Game = (p) => {


  var rightPressed = false;
  let img;
  let img_alt;
  let cw;
  let ch;
  let r = 55;
  let avatar;
  let win;
  let initWin;
  let clicked = false;
  let x;
  let y;
  let canvas;
  let xspeed = 5;
  let yspeed = 3;
  let changePoints;
  let currentPoints;
  let dummy;
  let fail;
  let initFail;

  p.myCustomRedrawAccordingToNewPropsHandler = (newProps) => {
    if(canvas){
      console.log("###### NEW PROPS");
      console.log(newProps);
      currentPoints = newProps.points;
      dummy = newProps.dummy;
      p.addPoints = newProps.setPoints;
      p.setLifes = newProps.setLifes;
    }
  }

  p.preload  = function () {
    cw = window.innerWidth-40;
    ch = window.innerHeight-40;
    x = cw/2 - r;
    y = ch/2 - r;
    img = p.loadImage('assets/media/alice.png');
    img_alt = p.loadImage('assets/media/alice2.png');
  }



  p.setup  = function () {

    canvas = p.createCanvas(cw, ch);

  }

  p.draw  = function () {

    if(!win){
      generate_circle();
    } else {
      if(!initWin){
        winner();
      }
    }

    if(fail && !initFail){
      clicked = true;
      failed();
    }


  }

  const failed = () => {
    initFail = true;
    p.setLifes();
    setTimeout(function(){
      fail = false;
      initFail = false;
      clicked = false;
    },100);
  }

  const generate_circle = () => {

    p.background(0,0);
    p.clear();
    p.image(img, x-r, y-r, r*2, r*2);

    x += xspeed;
    y += yspeed;

    if (x > cw - r || x < r) {
      xspeed = -xspeed;
    }
    if (y > ch - r || y < r) {
      yspeed = -yspeed;
    }

  }


  p.touchStarted = function() {
    console.log("touchStarted");
    if(!clicked){
      clicked = true;

      let d = p.dist(p.mouseX, p.mouseY, x, y);
      if (d < 70) {
        console.log("INSIDE AVATAR");
        win = true;
      } else {
        if(!initFail){
          fail = true;
        }
      }

    }
  }

  p.touchEnded  = function () {
    console.log("touchEnded");
    clicked = false;
  }

  const randomNumber = (min, max) => {
    return Math.floor(Math.random() * (max - min) + min);
  }


  const winner = () => {

    initWin = true;
    p.clear();
    p.image(img_alt, x-r, y-r, r*2, r*2);

    setTimeout(function(){

      console.log("WINNER");
      p.addPoints();
      p.clear();
      xspeed = xspeed + randomNumber(1,10);
      yspeed = yspeed + randomNumber(1,10);
      //x = (Math.floor(Math.random() * cw) + 1) - r;
      //y = (Math.floor(Math.random() * ch) + 1) - r;

      x = randomNumber(150,cw) - r;
      y = randomNumber(150,ch) - r;

      console.log("X: "+x);
      console.log("Y: "+y);
      win = false;
      initWin = false;

    },2000);

  }

}

export default Game;