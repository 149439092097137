import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Modal, Button } from 'react-bootstrap';
import { BrowserRouter, Route, Switch, Link, Redirect } from "react-router-dom";
import Loading from '../_components/Loading';
import sad from '../assets/sad.svg';

const Gameover = () => {
  const [loading, setloading] = useState(false);
  const [redirect, setredirect] = useState(false);

  const startLoading = code => {
    setloading(true);
    setTimeout(function(){
      setredirect(true);
    }, 1000);
  };

  return(
    <div className="page gameover-page">
      <div className="center">
      {redirect ? <Redirect to='/play' /> : ""}
      {loading ? <Loading /> :
        <>
        <div className="icon-wrapper animated bounceIn">
          <img src={sad} alt="" />;
        </div>
        <h1 className="animated bounceIn delay-1s">Oh não!</h1>
        <p className="animated bounceIn delay-1s">Perdeste...</p>
        <Button href="#" onClick={() => startLoading()} className="animated bounceIn delay-2s">Repetir</Button>
        </>
      }
      </div>
    </div>
  );
}

export default Gameover;