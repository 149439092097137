import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { BrowserRouter, Route, Switch, Link, Redirect } from "react-router-dom";
import logo from '../assets/logo.svg';
import Loading from '../_components/Loading';
import { useCookies } from 'react-cookie';


const Start = (props) => {

  const [cookies, setCookie] = useCookies(['in_fullscreen']);
  const [loading, setloading] = useState(false);
  const [redirect, setredirect] = useState(false);


  const startLoading = code => {
    props.goFull();
    setloading(true);
    setCookie('in_fullscreen', "111", { path: '/' });
    setTimeout(function(){
      setredirect(true);
    }, 3000);
  };

    return(

      <div className="page start-page">
        <div className="center">
        {redirect ? <Redirect to='/play' /> : ""}
        {loading ? <Loading /> :
          <>
          <div className="logo-wrapper animated jackInTheBox delay-1s">
            <img src={logo} alt="Alice - O jogo" />;
          </div>
          <Button href="#" onClick={() => startLoading()} className="animated bounceIn delay-2s">Começar</Button>
          </>
        }
        </div>
      </div>


    );

}

export default Start;