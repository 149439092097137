import React, { useState, useEffect } from 'react';

const Loading = () => {
  return(
    <div className="loading-wrapper">
      <i class="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>
    </div>
  );
}

export default Loading;