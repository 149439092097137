import React, { useState, useEffect } from 'react';

const BackgroundEffect = (props) => {

    return(

      <div class="bg-effects">
      {props.type == "love" ?
      <>
        <div class="heart-1 heart"></div>
        <div class="heart-2 heart"></div>
        <div class="heart-3 heart"></div>
        <div class="heart-4 heart"></div>
        <div class="heart-5 heart"></div>
        </>
        : "" }
      </div>


    );

}

export default BackgroundEffect;