import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { BrowserRouter, Route, Switch, Link, Redirect } from "react-router-dom";
import classNames from "classnames";




const Lifes = (props) => {

  let lifes = props.lifes;

  const hearts = (heart) => {
    return (<span class={classNames({
      'fa': true,
      'fa-heart': (lifes >= heart ? true : false ),
      'fa-heart-o': (lifes < heart ? true : false )
    })}></span>);
  }

  return(
    <div className="lifes-wrapper">
      {hearts(1)}
      {hearts(2)}
      {hearts(3)}
    </div>
  );
}

const Header = (props) => {

    return(

      <div className="header-wrapper animated slideInDown">
        <Row className="d-flex align-items-center">
          <Col xs="5"><div className="counter">{props.points}</div></Col>
          <Col xs="7"><Lifes lifes={props.lifes} /></Col>
        </Row>
      </div>

    );

}

export default Header;